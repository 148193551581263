/* .MuiTablePagination-root {
    visibility:hidden !important;
} */

.background-title-search{
    background: rgb(232, 234, 245) !important;
}

.MuiButton-containedSecondary:hover{
    color: white;
}
